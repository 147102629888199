.reports {
    margin-bottom: 0px !important;
    padding-top: 80px !important;
    background-color: #eeecec !important;
    background-size: cover !important;
    height: auto;
}

.rct-block {
    background: white;
    padding: 10px;
    border-radius: 8px;
}

@media only screen and (max-width: 600px) {
    .reports {
        background-size: cover !important;
    }
}