.label {
    color: black;
    >span {
        color: red;
    }
}

.cards {
    justify-content: center;
}

.formlogin {
    margin-bottom: 0px !important;
    padding-top: 20px !important;
    background-image: url('../../images/bg_login.jpg') !important;
}

input {
    border: 1px solid #767676 !important;
    border-radius: 20px !important;
    padding-left: 15px !important;
}

select {
    border: 1px solid #767676 !important;
    border-radius: 20px !important;
    padding-left: 15px !important;
}

@media only screen and (max-width: 600px) {
    .formlogin {
        background-size: cover !important;
    }
}