.label {
    color: black;
    >span {
        color: red;
    }
}

.occupancyReportForm {
    margin-bottom: 0px !important;
    padding-top: 20px !important;
    background-color: #eeecec !important;
}

.mobiledownloadbtn {
    display: inline-block;
    font-weight: 600;
    background: #59C378;
    text-transform: capitalize;
    width: 100%;
    z-index: 1;
    position: relative;
    font-size: 16px;
    border: 0;
    color: #fff;
    outline: none;
    margin-top: 20px;
    border-radius: 40px;
    padding: 15px;
}

@media only screen and (max-width: 600px) {
    .occupancyReportForm {
        background-size: cover !important;
    }
    .mobiledownloadbtn {
        width: 84% !important;
    }
}