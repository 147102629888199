.nav-item:hover {
    >.dropdown-menu {
        display: block;
    }
}

.wpo-header-style-1 .wpo-site-header {
    background-color: rgb(255, 255, 255) !important;
}

.drop-user {
    >li {
        a {
            padding-left: 10px !important;
            color: black;
        }
    }
}