.homedashboard {
    margin-bottom: 0px !important;
    padding-top: 20px !important;
    background-color: #eeecec !important;
    background-size: cover !important;
    
    height: auto;
    overflow: auto !important;
}

.newhome{
    min-height: 100vh;
}
.modal-dialog {
    max-width: 630px;
}

.label {
    color: black;
    font-size: 13px;
    text-transform: uppercase;
    >span {
        color: red;
    }
}

.modal-title{
    color: #fff !important;
}

.new_button{
    width: 25%;
    border-color: rgb(47, 47, 143) !important;
    background-color: rgb(47, 47, 143) !important;
    border-radius: 20px !important;
    font-size: 13px !important;
    font-weight: 200 !important;
    text-transform: uppercase;
}

.form-control:focus{
    box-shadow: 0px 0px 3px rgb(47 47 143) !important;
}

.notdisplayed{
    display: none !important;
}

.displayed{
    display: flex !important;
    position: absolute !important;
    animation: myanim 1s ease forwards;
    
}

@keyframes myanim {
    100% {
       margin-top: -35px;
    }
 }

.img_new{
    opacity: 0.5 !important;
}

.close{
    background-color: #fff;
    border: none;
}

.list-inline {
    display: flex;
    justify-content: end;
}

.headtab {
    padding-right: 20px;
}

.hmob{
    width: 10% !important;
}

@media only screen and (max-width: 600px) {
    .homedashboard {
        background-size: cover !important;
    }
    .hmob {
        width: 25% !important;
    }
}