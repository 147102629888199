.label {
    color: black;
    >span {
        color: red;
    }
}

.topButton {
    position: absolute;
    top: 44px;
    right: 115px;
}

.cheangepassword {
    margin-bottom: 0px !important;
    padding-top: 20px !important;
    background-color: #eeecec !important;
}

@media only screen and (max-width: 600px) {
    .topButton {
        top: 28px;
        right: 14px;
    }
    .cheangepassword {
        background-size: cover !important;
    }
}